import React from 'react';
import { AddFriendLinkInfo } from '../../../assets/images';

const UserIdInfoModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-[90%] max-w-[400px] p-6">
        <div className="flex items-center gap-2 mb-6">
          <button onClick={onClose}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <h2 className="text-lg font-medium">Where to find Friend's User ID?</h2>
        </div>

        {/* Profile Preview */}
        <img 
          src={AddFriendLinkInfo} 
          alt="Add Friend Image" 
          className="w-full h-auto mb-6 rounded-md object-contain" 
        />

        <p className="text-center text-gray-600 mb-6">
          Your friend's User ID is displayed on their profile, allowing them to copy it & share it with you to engage in a Duel.
        </p>

        {/* Cancel Button */}
        <button 
          onClick={onClose}
          className="w-full py-3 text-black border border-black rounded-full"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UserIdInfoModal;
