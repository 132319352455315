import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '../../assets/images';

const PracticeResults = ({ quesAnsDataMap, totalQuestions, onPressExit }) => {
  const navigate = useNavigate();
  const correctAnswers = quesAnsDataMap.filter((q) => q.user_option === q.correct_answer).length;
  const paddedCorrectAnswers = correctAnswers < 10 ? `0${correctAnswers}` : correctAnswers;
  const handleReviewClick = () => {
    navigate('/review', {
      state: {
        practice: {
          category: quesAnsDataMap[0].category,
          history_data: quesAnsDataMap,
        },
      },
    });
  };
  return (
    <div className="flex flex-col h-screen bg-white font-poppins">
      <div className="p-4 border-b flex items-center">
        <button onClick={onPressExit} className="mr-3">
          <img src={ArrowLeft} alt="Back" className="w-5 h-5" />
        </button>
        <h1 className="text-xl font-medium text-text">Exit Results</h1>
      </div>

      <div className="flex-1 p-4">
        <div className="text-center mb-6">
          <p className="text-sm font-semibold text-gray-500 uppercase">PRACTICE</p>
          <h2 className="text-2xl font-bold">Results</h2>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-6 border border-border">
          <div className="flex items-center mb-4">
            <svg className="w-6 h-6 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
            <span className="flex-1 font-medium">Correct Answers</span>
            <span className="font-medium">
              {paddedCorrectAnswers} / {totalQuestions}
            </span>
          </div>

          <div className="grid grid-cols-5 gap-2">
            {quesAnsDataMap.map((res, index) => (
              <div key={index} className={`flex flex-col items-center p-2 ${index !== 4 && index !== 9 && index !== 14 ? 'border-r' : ''}`}>
                <span className="text-sm mb-1">Q{index + 1}</span>
                {res.user_option === res.correct_answer ? (
                  <svg className="w-5 h-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                ) : (
                  <svg className="w-5 h-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </div>
            ))}
          </div>
        </div>

        <button
          //   onClick={() => {
          //     navigate('/post-analysis', {
          //       state: {
          //         chat_id: null,
          //         quesAnsMap: quesAnsDataMap,
          //         title: 'Practice Review',
          //       },
          //     });
          //   }}
          onClick={handleReviewClick}
          className="w-full bg-black text-white py-3 rounded-full mb-4 font-medium"
        >
          Practice Review
        </button>

        <button onClick={onPressExit} className="w-full border border-black py-3 rounded-full font-medium">
          Back to Practice
        </button>
      </div>
    </div>
  );
};

export default PracticeResults;
