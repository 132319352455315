import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, Timer } from '../../assets/images';
import { MarkdownWithLatex } from '../../molecules/RichText/MarkdownLatex';
import { userStore } from '../../stores/userStore';
import PracticeResults from './PracticeResults';
import { IconCheck, IconX } from '@tabler/icons-react';

export default function PracticeQuestions() {
  const location = useLocation();
  const navigate = useNavigate();
  const { questions } = location.state || {};
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(location.state?.settings?.timelimit ? 120 : null);
  const [showForceQuitModal, setShowForceQuitModal] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [saveApiRef, setSaveApiRef] = useState(false);
  const [practiceCompletedRef, setPracticeCompletedRef] = useState(false);
  const userData = userStore((state) => state.userData);

  useEffect(() => {
    setQuestionStates(
      questions.map(() => ({
        isSubmitted: false,
        selectedOption: null,
        isCorrect: false,
        answerIndex: null,
      }))
    );
  }, [questions]);

  useEffect(() => {
    if (location.state?.settings?.timelimit) {
      const timer = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 1) {
            handleTimeUp();
            return 120;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [currentQuestionIndex]);

  const handleTimeUp = () => {
    setQuestionStates((prev) => {
      const newStates = [...prev];
      newStates[currentQuestionIndex] = {
        ...newStates[currentQuestionIndex],
        isSubmitted: true,
        isCorrect: false,
      };
      return newStates;
    });
    setTimeout(() => {
      moveToNextQuestion();
    }, 1000);
  };

  const handleOptionSelect = (index) => {
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      setQuestionStates((prev) => {
        const newStates = [...prev];
        newStates[currentQuestionIndex].selectedOption = questions[currentQuestionIndex].options[index];
        newStates[currentQuestionIndex].answerIndex = index;
        return newStates;
      });
    }
  };
  const dictMyCategoryName = (category) => {
    const categories = {
      economy: 'economy',
      history: 'history',
      geography: 'geography',
      polity: 'polity',
      science: 'science',
      environment: 'environment',
      csat: 'csat',
    };

    const categoryLower = category?.toLowerCase();

    return categories[categoryLower] || 'current_affairs';
  };
  const submitPractice = () => {
    if (saveApiRef) {
      return;
    }

    saveHistoryData();
  };

  const moveToNextQuestion = () => {
    if (currentQuestionIndex === questions.length - 1) {
      submitPractice();
    } else {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };
  const saveHistoryData = async () => {
    if (saveApiRef) return;
    setSaveApiRef(true);

    const absCorrect = questionStates.filter((state) => state.isCorrect);
    const url = `${process.env.REACT_APP_BASE_URL}/api/practice/`;

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          profile: userData.user,
          user: userData.user,
          category: dictMyCategoryName(location.state.settings.selectedTopic.value),
          history_data: questionStates.map((state, index) => ({
            question_id: questions[index].question_id,
            question: questions[index].question,
            user_option: state.answerIndex,
            user_answer: questions[index].options[state.answerIndex],
            answer: state.answerIndex,
            correct_answer: questions[index].correct_answer,
            choices: questions[index].options,
            category: dictMyCategoryName(location.state.settings.selectedTopic.value),
            pyq_exam: questions[index].pyq_exam || null,
            pyq_year: questions[index].pyq_year || null,
            flow: 'practice',
          })),
          correct_answers: absCorrect.length,
          total_answers: location.state.settings.noOfQuestions,
          render_type: 'text',
          is_practice: true,
        }),
      });

      const json = await res.json();

      setQuizCompleted(true);
      setPracticeCompletedRef(true);
    } catch (err) {
      alert('Sorry, unable to save your practice. Please try again.');
    } finally {
      setSaveApiRef(false);
    }
  };

  const handleSubmit = () => {
    const isCorrect = questionStates[currentQuestionIndex].answerIndex === questions[currentQuestionIndex].correct_answer;
    setQuestionStates((prev) => {
      const newStates = [...prev];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isCorrect;
      return newStates;
    });
  };

  const handleForceQuit = () => {
    setShowForceQuitModal(true);
  };

  const currentQuestion = questions[currentQuestionIndex];

  if (quizCompleted) {
    // Log analytics events
    const eventData = {
      no_ques: location.state.settings.noOfQuestions,
      question_type: location.state.settings.questionType || 'default',
      category: dictMyCategoryName(location.state.settings.selectedTopic.value),
    };

    return (
      <>
        <PracticeResults
          quesAnsDataMap={questionStates.map((state, index) => ({
            question_id: questions[index].question_id,
            question: questions[index].question,
            user_option: state.answerIndex,
            correct_answer: questions[index].correct_answer,
            choices: questions[index].options,
            category: dictMyCategoryName(location.state.settings.selectedTopic.value),
          }))}
          totalQuestions={location.state.settings.noOfQuestions}
          onPressExit={() => navigate('/practice')}
        />
      </>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-white max-w-2xl mx-auto font-poppins">
      {/* Header */}
      <div className="px-4 py-2 flex  items-center">
        <button onClick={handleForceQuit} className="flex items-center text-gray-600 mr-3">
          <img src={ArrowLeft} alt="logo" className="w-5 h-5" />
        </button>
        <div className="text-lg font-semibold">Practice</div>
      </div>

      {/* Progress Bar */}
      <div className="flex w-full h-1 gap-1">
        {questions.map((_, index) => (
          <div
            key={index}
            className={`flex-1 h-full ${
              questionStates[index]?.isSubmitted
                ? questionStates[index]?.isCorrect
                  ? 'bg-green-500'
                  : 'bg-red-500'
                : index === currentQuestionIndex
                ? 'bg-blue-500'
                : 'bg-gray-200'
            }`}
          />
        ))}
      </div>

      {/* Question Content */}
      <div className="flex-1 overflow-y-auto p-4">
        <div className="mb-4">
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">
              QUESTION {String(currentQuestionIndex + 1).padStart(2, '0')}/{String(location.state?.settings?.noOfQuestions).padStart(2, '0')}
            </span>
            {location.state?.settings?.timelimit && timeRemaining && (
              <div className="flex items-center bg-blue-50 px-3 py-1 rounded-full">
                <img src={Timer} alt="timer" className="w-4 h-4 mr-1" />
                <span className="text-blue-600">{timeRemaining}</span>
              </div>
            )}
          </div>
        </div>

        <p className="text-gray-800 font-medium mb-6">
          {currentQuestion?.question.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              <MarkdownWithLatex content={line} />
            </React.Fragment>
          ))}
        </p>
        <div className="space-y-3">
          {currentQuestion?.options.map((option, index) => {
            const isSubmitted = questionStates[currentQuestionIndex]?.isSubmitted;
            const isSelected = questionStates[currentQuestionIndex]?.answerIndex === index;
            const isCorrect = index === currentQuestion.correct_answer;

            return (
              <button
                key={index}
                onClick={() => handleOptionSelect(index)}
                disabled={isSubmitted}
                className={`w-full p-4 rounded-lg border flex items-center space-x-3
                  ${
                    isSubmitted
                      ? isCorrect
                        ? 'bg-green-50 border-green-500'
                        : isSelected
                        ? 'bg-red-50 border-red-500'
                        : 'border-gray-200'
                      : isSelected
                      ? 'bg-blue-50 border-blue-500'
                      : 'border-gray-200'
                  }`}
              >
                <div
                  className={`w-6 h-6 rounded-full flex items-center justify-center
                    ${
                      isSubmitted
                        ? isCorrect
                          ? 'bg-green-500'
                          : isSelected
                          ? 'bg-red-500'
                          : 'border-2 border-gray-300'
                        : isSelected
                        ? 'border-2 border-blue-500'
                        : 'border-2 border-gray-300'
                    }`}
                >
                  {isSubmitted ? (
                    isCorrect ? (
                      <IconCheck className="w-4 h-4 text-white" />
                    ) : isSelected ? (
                      <IconX className="w-4 h-4 text-white" />
                    ) : null
                  ) : isSelected ? (
                    <div className="w-3 h-3 bg-blue-500 rounded-full" />
                  ) : null}
                </div>
                <span className="font-medium">{String.fromCharCode(65 + index)}.</span>
                <span className="text-left flex-1">
                  <MarkdownWithLatex content={option} />
                </span>
              </button>
            );
          })}
        </div>
      </div>

      {/* Footer */}
      <div className="p-4 flex justify-between shadow-[0px_-4px_24px_0px_rgba(0,0,0,0.12)]">
        {questionStates[currentQuestionIndex]?.isSubmitted ? (
          <>
            <button onClick={() => console.log('Discuss with AI')} className="border border-blue-500 text-blue-500 px-4 py-2 rounded-lg">
              Discuss with AI
            </button>
            <button onClick={moveToNextQuestion} className="bg-black text-white px-6 py-2 rounded-full">
              {currentQuestionIndex === location.state?.settings?.noOfQuestions - 1 ? 'Finish' : 'Next Question'}
            </button>
          </>
        ) : (
          <button
            onClick={handleSubmit}
            disabled={questionStates[currentQuestionIndex]?.answerIndex === null}
            className={`w-full px-6 py-2 rounded-full ${
              questionStates[currentQuestionIndex]?.answerIndex !== null ? 'bg-black text-white' : 'bg-gray-100 text-gray-400'
            }`}
          >
            Submit Answer
          </button>
        )}
      </div>

      {/* Force Quit Modal */}
      {showForceQuitModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg m-3">
            <h2 className="text-lg font-semibold mb-4">Exit Practice?</h2>
            <p className="mb-4">Are you sure you want to exit the ongoing practice session?</p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowForceQuitModal(false)} className="text-blue-500">
                Cancel
              </button>
              <button onClick={() => navigate(-1)} className="text-red-500">
                Yes, Exit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
