import React from 'react';
import { One, Two, Three, Tower, WinnerCrown, BlueCrown, BlueStar, BlueCheck, NoProfile } from '../../assets/images';

const LeaderboardTower = ({ globalLeaderboard, userRanking, activeTab }) => {
  const isRespMobile = window.innerWidth <= 700;
  const getDisplayValue = (user) => {
    if (activeTab === 'Performance') {
      return user.totalcorr || '-';
    }
    return user.score || '-';
  };

  const fullLeaderboard =
    globalLeaderboard && globalLeaderboard.length > 0
      ? [
          globalLeaderboard[1] || { rank: 2, name: '-', totalcorr: '-', score: '-', photo: null },
          globalLeaderboard[0] || { rank: 1, name: '-', totalcorr: '-', score: '-', photo: null },
          globalLeaderboard[2] || { rank: 3, name: '-', totalcorr: '-', score: '-', photo: null },
        ]
      : [
          { rank: 2, name: '-', totalcorr: '-', score: '-', photo: NoProfile },
          {
            rank: 1,
            name: userRanking && userRanking.name ? userRanking.name : '-',
            totalcorr: userRanking && userRanking.totalcorr ? userRanking.totalcorr : '-',
            score: userRanking && userRanking.score ? userRanking.score : '-',
            photo: userRanking && userRanking.photo ? userRanking.photo : null,
          },
          { rank: 3, name: '-', totalcorr: '-', score: '-', photo: NoProfile },
        ];

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };
  const marginTopValue =
    window.innerHeight < 900 && window.innerWidth < 400
      ? '65px'
      : window.innerWidth < 1800
      ? activeTab === 'Performance'
        ? '2dvh'
        : '20dvh'
      : '10px';

  return (
    <div style={{ ...styles.podiumContainer, marginTop: marginTopValue }}>
      <img
        src={BlueStar}
        style={{ ...styles.starOne, top: activeTab === 'Performance' ? '270px' : '110px', left: isRespMobile ? '20px' : '43px' }}
        alt="starOne"
      />
      {fullLeaderboard.map((user, index) => (
        <div key={user.rank} style={styles.column}>
          <div style={styles.profileCircle}>
            {user.photo ? (
              <img src={user.photo} alt={user.name} style={styles.profileImage} />
            ) : (
              <div style={styles.initial}>{getInitial(user.name)}</div>
            )}
            {index === 1 && (
              <div style={styles.crown}>
                <img src={WinnerCrown} alt="crown" />
              </div>
            )}
          </div>
          <p style={styles.name}>{user.name}</p>

          <div
            style={{
              ...styles.podium,
              ...(index === 0 ? styles.second : index === 1 ? styles.first : styles.third),
            }}
          >
            {getDisplayValue(user) !== '-' && (
              <div style={styles.podiumRank}>
                <img
                  src={activeTab === 'Performance' ? BlueCheck : BlueCrown}
                  alt={activeTab === 'Performance' ? 'Check Icon' : 'Crown Icon'}
                  style={styles.crownIcon}
                />
                {getDisplayValue(user)}
              </div>
            )}

            <img src={index === 0 ? Two : index === 1 ? One : Three} alt={`Rank ${user.rank}`} style={styles.avatar} />
          </div>
        </div>
      ))}

      <img src={BlueStar} style={{ ...styles.starTwo, top: activeTab === 'Performance' ? '180px' : '30px' }} alt="starTwo" />
    </div>
  );
};

const styles = {
  podiumContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 10px',
    width: '80px',
  },
  profileCircle: {
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #BDCBFF',
    position: 'relative',
    marginTop: '20px',
  },
  profileImage: {
    width: '90%',
    height: '90%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  crown: {
    position: 'absolute',
    top: '-28px',
    fontSize: '24px',
  },
  podium: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#f0f0f0',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    position: 'relative',
    backgroundImage: `url(${Tower})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: '20px',
  },
  podiumRank: {
    position: 'absolute',
    top: '-25px',
    backgroundColor: 'white',
    color: '#3C45CD',
    fontWeight: 'bold',
    fontSize: '16px',
    padding: '5px 10px',
    borderRadius: '24px',
    border: '1px solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
  },
  first: {
    height: window.innerHeight <= 700 ? '350px' : '550px',
    width: '90px',
    marginBottom: '0',
  },
  second: {
    height: window.innerHeight <= 700 ? '310px' : '510px',
    width: '90px',
  },
  third: {
    height: window.innerHeight <= 700 ? '270px' : '470px',
    width: '90px',
  },
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginBottom: '10px',
    marginTop: '20px',
  },
  rank: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: 'auto',
    marginBottom: '10px',
  },
  initial: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#555',
  },
  name: {
    fontSize: '14px',
    fontWeight: '600',
    paddingBottom: '15px',
    minHeight: '30px',
  },
  starOne: {
    position: 'absolute',
    opacity: '0.5',
  },
  starTwo: {
    position: 'absolute',
    right: '43px',
    opacity: '0.5',
  },
  '@media (max-width: 700px)': {
    podiumContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    column: {
      width: '100%',
      margin: '10px 0',
    },
    profileCircle: {
      width: '48px',
      height: '48px',
      marginTop: '15px',
    },
    podium: {
      width: '70px',
    },
    avatar: {
      width: '40px',
      height: '40px',
    },
  },
};

export default LeaderboardTower;
