import React from 'react';

const InviteFriendModal = ({ 
  friendName, 
  friendScore, 
  topic, 
  onAccept, 
  onDecline,
  isLoading 
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div className="bg-white rounded-lg p-6 w-80 shadow-lg">
        <h2 className="text-center text-lg font-medium mb-4">
          Duel Invite Sent
        </h2>

        <div className="flex flex-col items-center mb-6">
          <div className="bg-blue-100 rounded-full p-4 mb-2">
            <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
              {friendName?.[0]?.toUpperCase()}
            </div>
          </div>
          <p className="font-medium">{friendName}</p>
          {friendScore > 0 && (
            <div className="flex items-center gap-1">
              <svg className="w-4 h-4 text-purple-500" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9 6a3 3 0 100-6 3 3 0 000 6zM17 6a3 3 0 100-6 3 3 0 000 6zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              <span className="text-purple-500">{friendScore}</span>
            </div>
          )}
        </div>

        <div className="mb-6">
          <p className="text-gray-500 text-center text-sm mb-2">DUEL TOPIC</p>
          <div className="flex items-center justify-center gap-2 bg-green-50 py-2 rounded-lg">
            <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9.5a2.5 2.5 0 00-2.5-2.5H15" />
            </svg>
            <span className="text-green-600">{topic}</span>
          </div>
        </div>

        <div className="flex gap-3">
          <button
            onClick={onDecline}
            className="flex-1 py-2 px-4 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-50 transition-colors"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            onClick={onAccept}
            className="flex-1 py-2 px-4 rounded-lg bg-purple-600 text-white hover:bg-purple-700 transition-colors"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Start Duel"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteFriendModal;