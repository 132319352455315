import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../constants/iconData';
import { ArrowDown, ArrowRight, RedCrown, CloseIcon } from '../../assets/images';
import { userStore } from '../../stores/userStore';

const subjects = [
  { value: 'csat', label: 'CSAT' },
  { value: 'history', label: 'History' },
  { value: 'geography', label: 'Geography' },
  { value: 'polity', label: 'Polity' },
  { value: 'current_affairs', label: 'Current Affairs' },
  { value: 'economy', label: 'Economy' },
  { value: 'science', label: 'Science' },
  { value: 'environment', label: 'Environment' },
];

const questionCounts = [5, 10, 15];

const questionTypes = [
  { value: 'all', label: 'All Questions', description: 'Practice from the entire PadhAI collection' },
  { value: 'favorites', label: 'Favorites', description: 'Practice questions from your Favorite List' },
  { value: 'pyq', label: 'PYQ Only', description: 'Practice previous year questions' },
  { value: 'incorrect', label: 'Incorrect Only', description: 'Practice incorrect questions' },
];

const beginPractice = async (config) => {
  const { userId, topic, questionCount, isPyq, isFavorites, isIncorrect, isAllQuestions, isPadhaiList, padhaiListId } = config;

  let url = isPadhaiList
    ? `${process.env.REACT_APP_BASE_URL}/api/padhai-list/questions/?user=${userId}&padhai_list_id=${padhaiListId}&max_questions=${questionCount}`
    : `${process.env.REACT_APP_BASE_URL}/api/practice/?category=${topic}&user=${userId}&max_questions=${questionCount}&render_type=text`;

  const queryParams = [];
  if (isPyq) queryParams.push('question_type=pyq');
  if (isFavorites) queryParams.push('favorites=true');
  if (isIncorrect) queryParams.push('incorrect=true');
  if (isAllQuestions) queryParams.push('allquestions=true');

  if (queryParams.length) {
    url += `&${queryParams.join('&')}`;
  }

  try {
    const response = await fetch(url);
    return await response.json();
  } catch (error) {
    console.error('Error fetching practice questions:', error);
    return [];
  }
};

export default function Component() {
  const userData = userStore((state) => state.userData);
  const navigate = useNavigate();
  const [state, setState] = useState({
    noOfQuestions: 5,
    questionType: 'all',
    selectedTopic: subjects[0],
    favorites: false,
    allQuestions: true,
    pyq: false,
    timelimit: false,
    incorrect: false,
    loading: false,
    errorModal: false,
    errorPopup: false,
    padhaiListData: [],
    selectedPadhaiList: '',
    selectedPadhaiListId: null,
  });

  const handleTopicChange = (topic) => {
    setState((prev) => ({
      ...prev,
      selectedTopic: topic,
      isDropdownOpen: false, // Close the dropdown
    }));
  };
  const handleStartPractice = async () => {
    setState((prev) => ({ ...prev, loading: true }));

    const questions = await beginPractice({
      userId: userData.user,
      topic: state.selectedTopic.value,
      questionCount: state.noOfQuestions,
      isPyq: state.pyq,
      isFavorites: state.favorites,
      isIncorrect: state.incorrect,
      isAllQuestions: state.allQuestions,
      isPadhaiList: false,
      padhaiListId: state.selectedPadhaiListId,
    });

    if (questions.length > 0) {
      if (questions.length < state.noOfQuestions) {
        setState((prev) => ({ ...prev, errorModal: true, loading: false }));
      } else {
        navigate('/practice-questions', {
          state: {
            questions,
            settings: {
              noOfQuestions: questions.length,
              questionType: state.questionType,
              selectedTopic: state.selectedTopic,
              favorites: state.favorites,
              pyq: state.pyq,
              timelimit: state.timelimit,
              incorrect: state.incorrect,
            },
          },
        });
      }
    } else {
      setState((prev) => ({ ...prev, errorPopup: true, loading: false }));
    }
  };

  return (
    <>
      <div className="flex flex-col h-[95dvh] bg-white p-6 max-w-md mx-auto font-poppins custom-scrollbar">
        <h2 className="text-sm font-semibold text-gray-500 mb-1 text-center">PRACTICE</h2>
        <h1 className="text-2xl font-bold mb-6 text-center">Customize your Practice</h1>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">TOPIC</label>
          <div className="relative">
            <button
              onClick={() => setState((prev) => ({ ...prev, isDropdownOpen: !prev.isDropdownOpen }))}
              className="w-full flex items-center justify-between p-2 border border-gray-300 rounded-md bg-white"
            >
              <div className="flex items-center">
                {icons[state.selectedTopic.value]}
                <span className="ml-2">{state.selectedTopic.label}</span>
              </div>
              <img src={ArrowDown} alt="arrow-down" className="h-5 w-5" />
            </button>
            {state.isDropdownOpen && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-72 overflow-auto custom-scrollbar">
                {subjects.map((subject) => (
                  <button
                    key={subject.value}
                    onClick={() => handleTopicChange(subject)}
                    className={`flex items-center justify-between w-full px-4 py-2 text-left hover:bg-gray-100 ${
                      state.selectedTopic.value === subject.value ? 'bg-blue-50' : ''
                    }`}
                  >
                    <div className="flex items-center">
                      {icons[subject.value]}
                      <span className="ml-2">{subject.label}</span>
                    </div>
                    {state.selectedTopic.value === subject.value && <span className="text-blue-500">✓</span>}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-1">MAX NO. OF QUESTIONS</label>
          <div className="flex space-x-4">
            {questionCounts.map((count) => (
              <button
                key={count}
                onClick={() => setState((prev) => ({ ...prev, noOfQuestions: count }))}
                className={`flex-1 py-2 border ${state.noOfQuestions === count ? 'bg-blue-100 border-blue-500' : 'border-gray-300'} rounded-md`}
              >
                {count}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="checkbox"
              checked={state.timelimit}
              onChange={() => setState((prev) => ({ ...prev, timelimit: !prev.timelimit }))}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="text-lg font-medium text-gray-700">Practice with a timer</span>
            {/* <Timer className="h-5 w-5 text-gray-400" /> */}
          </label>
        </div>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-1">QUESTION TYPE</label>
          {questionTypes.map((type) => (
            <label key={type.value} className="flex items-start space-x-2 mb-2 cursor-pointer">
              <input
                type="radio"
                value={type.value}
                checked={state.questionType === type.value}
                onChange={() => setState((prev) => ({ ...prev, questionType: type.value }))}
                className="form-radio h-5 w-5 text-blue-600 mt-1"
              />
              <div>
                <span className="text-lg font-medium text-gray-700">{type.label}</span>
                <p className="text-sm text-gray-500">{type.description}</p>
              </div>
            </label>
          ))}
        </div>
      </div>
      {/* Footer */}
      <div className="fixed bottom-0 left-0 right-0 flex justify-between text-md shadow-[0px_-4px_24px_0px_rgba(0,0,0,0.12)] p-3 z-10 bg-cardBackground">
        <button onClick={() => navigate(-1)} className="px-6 py-2 border border-gray-300 rounded-full text-gray-700">
          Go back
        </button>
        <button onClick={handleStartPractice} className="px-6 py-2 bg-blue-600 text-white rounded-full flex items-center">
          Begin Practice
          <img src={ArrowRight} alt="arrow-right" className="ml-2 h-5 w-5" />
        </button>
      </div>

      {/* Error Modal */}
      {state.errorModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md mx-auto">
            <h3 className="text-xl font-semibold mb-2">Not Enough Questions Found</h3>
            <p className="text-gray-600 mb-4">It seems there aren't enough questions matching your filters.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setState((prev) => ({ ...prev, errorModal: false }))} className="px-4 py-2 border border-gray-300 rounded-full">
                Edit Filters
              </button>
              <button onClick={handleStartPractice} className="px-4 py-2 bg-blue-600 text-white rounded-full">
                Begin Anyway
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Error Popup */}
      {state.errorPopup && (
        <div className="fixed bottom-4 left-4 right-4 bg-white rounded-lg shadow-lg p-4 flex items-start">
          <div className="flex-shrink-0 mr-4">
            <img src={RedCrown} alt="error-icon" className="h-6 w-6 text-red-500" />
          </div>
          <div className="flex-1">
            <h4 className="font-medium">No questions found.</h4>
            <p className="text-gray-600">Sorry, we couldn't find any question matching your filters. Please, try with different choices.</p>
          </div>
          <button onClick={() => setState((prev) => ({ ...prev, errorPopup: false }))} className="ml-4">
            <img src={CloseIcon} alt="close-icon" className="h-5 w-5 text-gray-400" />
          </button>
        </div>
      )}
    </>
  );
}
