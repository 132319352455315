import React, { useState } from 'react';
import { PracticeCustom } from '../../assets/images';
import { useNavigate } from 'react-router-dom';

export default function DuelFirstTime() {
  const navigate = useNavigate();

  return (
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 class="text-2xl font-semibold mb-2">Custom Topic</h1>
      <p class="text-gray-600 mb-6 text-center">Level up your UPSC preparation with customized practice</p>

      <div className="bg-yellow-100 p-6 rounded-lg mb-6">
        <img src={PracticeCustom} alt="Custom Topic" className="w-64 h-auto" />
      </div>

      <button onClick={() => navigate('/customize-practice')} className="bg-black text-white py-3 px-8 rounded-full">
        Start Now
      </button>
    </div>
  );
}
