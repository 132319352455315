import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IconCheck, IconHeart, IconMessageCircle2, IconX, IconExclamationCircle } from '@tabler/icons-react';
import { ArrowLeft, ArrowRightFilled, ArrowLeftFilled } from '../../assets/images';
import { Button, Flex, Text } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../../context/themeProvider';
// import { useSpring, animated } from 'react-spring';
import ReportModaContent from '../../molecules/ReportQuestion';
import { userStore } from '../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { MarkdownWithLatex } from '../../molecules/RichText/MarkdownLatex';
import PropTypes from 'prop-types';

const DuelReview = () => {
  const location = useLocation();
  const reviewData = location.state?.duel || location.state?.practice;
  const reviewType = location.state?.duel ? 'duel' : 'practice';

  const questionData = useMemo(() => {
    if (reviewType === 'practice') {
      return reviewData.history_data.map((question) => ({
        ...question,
        category: question.category || reviewData.category,
        choices: question.choices.map((choice, index) => ({
          [String.fromCharCode(97 + index)]: choice,
        })),
        correct_answer: String.fromCharCode(97 + question.correct_answer),
        user_option: question.user_option !== null ? String.fromCharCode(97 + question.user_option) : null,
      }));
    }
    return reviewData.questionData;
  }, [reviewData, reviewType]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const navigate = useNavigate();
  const { colors } = useTheme();
  const containerRef = useRef(null);
  const isMobile = window.innerWidth <= 2500;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reportData, setReportData] = useState('');
  const [reportDetails, setReportDetails] = useState('');
  const [isReportSubmitted, setIsReportSubmitted] = useState(false);
  const userData = userStore((state) => state.userData);
  const userId = userData.user;

  const handleReportOpen = () => setIsOpen(true);

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsReportSubmitted(false);
    setReportDetails('');
    setReportData('');
  };

  const handleReportSubmit = async () => {
    setIsReportSubmitted(true);
    let postPayload = {
      flow: reviewType,
      question_id: questionData[currentQuestionIndex].question_id,
      user: userId,
      option: reportData,
      reason: '',
    };

    if (reportData === 'Other') {
      postPayload.reason = reportDetails;
    }

    MixpanelEvent('submit_feedback', {
      category: questionData[currentQuestionIndex].category,
      source: `${reviewType}_review`,
    });

    sendPostHogEvent('submit_feedback', {
      selection: reportData,
      question_id: questionData[currentQuestionIndex].question_id,
      category: questionData[currentQuestionIndex].category,
      source: `${reviewType}_review`,
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        handleCloseModal();
      }, 3000);
    } catch (error) {}
  };

  // const [props, set] = useSpring(() => ({
  //   x: 0,
  //   config: { tension: 300, friction: 30 },
  // }));

  useEffect(() => {
    if (!reviewData) {
      navigate('/duel');
    }
  }, [reviewData, navigate]);

  if (!reviewData) return null;

  const currentQuestion = questionData[currentQuestionIndex];

  const backClick = () => {
    if (reviewType === 'practice') {
      navigate('/practice');
    } else {
      navigate(-1);
    }
    MixpanelEvent('navigation_back', { source_screen: `${reviewType}_review` });
    sendPostHogEvent('navigation_back', { source_screen: `${reviewType}_review` });
  };

  const discussWithAi = (question, selectedOption) => {
    const optionKey = ['a', 'b', 'c', 'd'];

    const aiTutorParams = {
      flowType: 'postGame',
      source: reviewType,
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: question.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: question.user_answer,
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: question.choices.map((option, idx) => ({
          [optionKey[idx]]: option,
        })),
        category: question.category,
        question: question.question,
        question_id: question.question_id,
        user_answer: question.choices[selectedOption],
        user_option: question.user_answer,
        correct_answer: question.user_option,
        options: question.choices,
      },
      category: question.category,
      preSelectAnswer: question.choices[selectedOption],
    };
    return aiTutorParams;
  };

  const handleDiscussWithAi = () => {
    MixpanelEvent('discuss_with_ai', {
      source: `${reviewType}_review`,
      category: reviewType === 'practice' ? reviewData.category : reviewData.category,
    });
    sendPostHogEvent('discuss_with_ai', {
      source: `${reviewType}_review`,
      category: reviewType === 'practice' ? reviewData.category : reviewData.category,
    });
    const aiTutorParams = discussWithAi(currentQuestion, currentQuestion.user_option);
    navigate(`/chat/new/`, { state: { aiTutorParams } });
  };

  const handleNextQuestion = () => {
    // set({ x: -window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex < questionData.length - 1 ? prevIndex + 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handlePreviousQuestion = () => {
    // set({ x: window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    // set({ x: touchStart - e.targetTouches[0].clientX });
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleNextQuestion();
    } else if (isRightSwipe) {
      handlePreviousQuestion();
    } else {
      // set({ x: 0 });
    }
  };

  const isCorrect = currentQuestion.user_option === currentQuestion.correct_answer;

  return (
    <div style={styles.outerContainer}>
      <div style={styles.topContainer}>
        <Flex align="center" style={styles.header}>
          <img onClick={backClick} src={ArrowLeft} alt="Arrow Left" style={styles.backArrow} />
          <Text style={styles.headerText}>{reviewType === 'duel' ? 'Duel Review' : 'Practice Review'}</Text>
        </Flex>

        <div style={styles.questionNavigation}>
          <img
            src={ArrowLeftFilled}
            style={{
              ...styles.navIcon,
              color: currentQuestionIndex === 0 ? 'gray' : colors.arrowColor,
              cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
              opacity: currentQuestionIndex === 0 ? 0.5 : 1,
            }}
            onClick={handlePreviousQuestion}
            alt="Arrow Left"
          />
          <div style={styles.qNavigationWrapper}>
            <div style={styles.qNavigation}>
              {questionData.map((_, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.questionNumber,
                    backgroundColor: index === currentQuestionIndex ? '#EBF3FF' : 'transparent',
                    color: index === currentQuestionIndex ? '#6374FA' : 'black',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  Q{index + 1}
                </Text>
              ))}
            </div>
          </div>
          <img
            src={ArrowLeftFilled}
            style={{
              ...styles.navIcon,
              color: currentQuestionIndex === questionData.length - 1 ? 'gray' : colors.arrowColor,
              cursor: currentQuestionIndex === questionData.length - 1 ? 'not-allowed' : 'pointer',
              opacity: currentQuestionIndex === questionData.length - 1 ? 0.5 : 1,
              transform: 'rotate(180deg)',
            }}
            onClick={handleNextQuestion}
            alt="Arrow Right"
          />
        </div>
      </div>

      <div style={isCorrect ? styles.correctAnswerBanner : styles.incorrectAnswerBanner}>
        Question {currentQuestionIndex + 1} | {isCorrect ? 'Correct' : 'Incorrect'} Answer
      </div>
      <div style={styles.container} ref={containerRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
        {/* <animated.div style={{ ...props, ...styles.animatedContainer }}> */}
        <div style={styles.animatedContainer}>
          <div
            style={{
              ...styles.bottomContainer,
              backgroundColor: colors.cardBackground,
              // padding: '15px',
              height: '88%',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              WebkitOverflowScrolling: 'touch',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <div style={styles.questionMeta}>
              <Text style={styles.questionCategory}>{currentQuestion.category.toUpperCase()}</Text>
              <IconHeart style={styles.heartIcon} />
            </div>

            <Text style={styles.questionText}>
              {currentQuestion.question.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  <MarkdownWithLatex content={line} />
                </React.Fragment>
              ))}
            </Text>

            <div style={styles.optionsContainer}>
              {currentQuestion.choices.map((choice, index) => {
                const [key, value] = Object.entries(choice)[0];
                const isCorrectAnswer = key === currentQuestion.correct_answer;
                const isUserAnswer = key === currentQuestion.user_option;
                const isWrongUserAnswer = isUserAnswer && !isCorrectAnswer;

                return (
                  <div
                    key={key}
                    style={{
                      ...styles.optionBox,
                      backgroundColor: isCorrectAnswer ? '#E4FFEF' : isWrongUserAnswer ? '#FFB6B6' : 'white',
                      border: isCorrectAnswer ? '1px solid #34d399' : isWrongUserAnswer ? '1px solid #ff9999' : '1px solid #e0e0e0',
                    }}
                  >
                    <div style={styles.radioContainer}>
                      <div
                        style={{
                          ...styles.radioCircle,
                          borderColor: isUserAnswer ? (isCorrectAnswer ? '#34d399' : '#ff9999') : '#e0e0e0',
                        }}
                      >
                        {isCorrectAnswer && <IconCheck style={styles.checkIcon} />}
                        {isWrongUserAnswer && <IconX style={styles.xIcon} />}
                      </div>
                    </div>
                    <Text
                      style={{
                        ...styles.optionText,
                        color: isCorrectAnswer ? '#059669' : isWrongUserAnswer ? '#cc0000' : '#333',
                      }}
                    >
                      {key.toUpperCase()}. &nbsp; <MarkdownWithLatex content={value} />
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
          boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
          width: '100%',
          backgroundColor: colors.cardBackground,
          border: `1px solid ${colors.border}`,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          boxSizing: 'border-box',
          margin: 0,
        }}
      >
        <Button style={styles.reportButton} onClick={handleReportOpen}>
          <IconExclamationCircle style={{ marginRight: '0.25rem' }} height={18} width={18} />
          Report
        </Button>
        <Button style={styles.discussButton} onClick={handleDiscussWithAi}>
          Discuss with AI
        </Button>
      </div>
      <ReportModaContent
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={reportData}
        setResportData={setReportData}
        details={reportDetails}
        onChange={(e) => setReportDetails(e.currentTarget.value)}
        handleResportSubmit={handleReportSubmit}
        isReportSubmited={isReportSubmitted}
        isMobile={isMobile}
      />
    </div>
  );
};
const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;

const styles = {
  outerContainer: {
    fontFamily: 'Poppins, Arial, sans-serif',
    color: '#333',
    maxWidth: '600px',
    margin: '0 auto',
    height: '90dvh',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
  },
  container: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    touchAction: 'pan-y',
    border: '1px solid #E8E8E8',
    margin: '15px 15px 0px 15px',
    scrollbarWidth: 'none',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  animatedContainer: {
    width: '100%',
    willChange: 'transform',
  },
  topContainer: {
    padding: '15px',
    background: 'white',
    boxShadow: '0px 4px 9.2px 0px rgba(0, 0, 0, 0.10)',
  },
  bottomContainer: {
    margin: '10px',
    background: 'white',
    borderRadius: '12px',
    marginTop: '15px',
  },
  header: {
    marginBottom: '20px',
  },
  backArrow: {
    cursor: 'pointer',
    marginRight: '10px',
  },
  headerText: {
    fontSize: '20px',
    fontWeight: '600',
  },
  questionNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  qNavigationWrapper: {
    flex: 1,
    overflow: 'hidden',
    margin: '0 10px',
  },
  qNavigation: {
    display: 'flex',
    overflowX: 'auto',
    scrollbarWidth: 'none', // Firefox
    msOverflowStyle: 'none', // IE 10+
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none', // Safari and Chrome
    },
  },
  navIcon: {
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid #D1D1D1',
    padding: '7px 5px',
  },
  questionNumber: {
    minWidth: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    margin: '0 5px',
    flexShrink: 0,
  },
  correctAnswerBanner: {
    backgroundColor: '#e6f7ed',
    color: '#059669',
    padding: '10px',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 'auto',
    width: 'fit-content',
    borderRadius: '20px',
    position: 'relative',
    top: '29px',
    zIndex: '9',
  },
  incorrectAnswerBanner: {
    backgroundColor: '#ffe6e6',
    color: '#cc0000',
    padding: '10px',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 'auto',
    width: 'fit-content',
    borderRadius: '20px',
    position: 'relative',
    top: '29px',
    zIndex: '9',
  },
  questionMeta: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    gap: '10px',
  },
  questionCategory: {
    fontSize: '12px',
    color: '#888',
    padding: '4px 8px',
    borderRadius: '4px',
    background: '#F3F3F3',
    fontWeight: '600',
    textAlign: 'center',
  },
  heartIcon: {
    color: '#888',
    cursor: 'pointer',
  },
  questionText: {
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '20px',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  optionBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  radioContainer: {
    marginRight: '10px',
  },
  radioCircle: {
    width: '23px',
    height: '23px',
    borderRadius: '50%',
    border: '2px solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionText: {
    fontSize: '15px',
    fontWeight: '500',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  checkIcon: {
    backgroundColor: '#61BD85',
    width: '21px',
    height: '20px',
    borderRadius: '50%',
    color: '#E4FFEF',
    padding: '2px',
  },
  xIcon: {
    backgroundColor: '#D95353',
    width: '21px',
    height: '20px',
    borderRadius: '50%',
    color: '#FFB6B6',
    padding: '2px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: isResponsiveMobiles ? '4px' : '20px',
    position: 'relative',
    bottom: '0',
    padding: '15px',
    borderRadius: '12px',
    border: '1px solid  #E8E8E8',
    boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
    width: '100%',
    backgroundColor: 'none',
  },
  reportButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    backgroundColor: 'white',
    color: '#ff0000',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
  },
  reportIcon: {
    marginRight: '5px',
  },
  discussButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    color: 'black',
    border: '1px solid black',
    backgroundColor: 'white',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
  },
  discussIcon: {
    marginRight: '5px',
  },
};

DuelReview.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      duel: PropTypes.object,
      practice: PropTypes.object,
    }),
  }).isRequired,
};

export default DuelReview;
