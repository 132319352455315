import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text, Space } from '@mantine/core';
import { ArrowLeft, DuelStateIcon, Edit2, newsFeedInfo, ProfilePic, DownloadSvg, Bell } from '../../assets/images';
import ThemeToggle from './ThemeToggle';
import { routeTitles } from '../../constants/headerTitle';
import { useTheme } from '../../context/themeProvider';
import useInstallPromptStore from '../../stores/eventListenerStore';
import {
  initMixpanel,
  identifyUser,
  setMixpanelUserProperties,
  MixpanelEvent,
  initPostHog,
  sendPostHogEvent,
  setPostHogUserProperties,
} from '../../utils/mixpanelUtil'; // Import named exports
import moengage from '@moengage/web-sdk';
import { userStore } from '../../stores/userStore';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { colors, colorScheme } = useTheme();
  const isNewsRoute = location.pathname.startsWith('/news');
  const appliedColors = isNewsRoute ? colors : { text: '#000', cardBackground: '#f9f9f9' };

  const [profilePic, setProfilePic] = useState(ProfilePic);
  const [visitedNewsFeed, setVisitedNewsFeed] = useState(false);
  const deferredPrompt = useInstallPromptStore((state) => state.deferredPrompt);
  const isPromptFired = useInstallPromptStore((state) => state.isPromptFired);
  const clearDeferredPrompt = useInstallPromptStore((state) => state.clearDeferredPrompt);
  const userData = userStore((state) => state.userData);
  const userId = userData?.user;
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData && userData.photo) {
      setProfilePic(userData.photo);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/news') {
      setVisitedNewsFeed(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const promptFired = window.sessionStorage.getItem('deferredPromptFired');
    if (isPromptFired || promptFired) {
      setShowInstallButton(true);
    }
  }, [isPromptFired]);

  useEffect(() => {
    moengage.initialize({ app_id: '7DJH2RCLMVG8GU54LCGKV2R0', cluster: 'DC_3', debug_logs: 0, swPath: '/serviceworker.js' });

    // Initialize Mixpanel and set user properties
    initMixpanel();
    initPostHog();
    identifyUser(userId);
    // setPostHogUserProperties({
    //   id: userId,
    // });
    setMixpanelUserProperties({
      email: userData.email,
      name: userData.name,
      phone: userData.phone,
    });

    moengage.add_unique_user_id(userId);
    moengage.add_mobile(userData.phone);
    moengage.add_first_name(userData.name);

    var moeData = localStorage.getItem('MOE_DATA');
    moeData = JSON.parse(moeData);
    console.log('moeData', moeData);

    const oneWeekInMilliseconds = 604800000; // 7 * 24 * 60 * 60 * 1000
    const currentTime = Date.now();

    if (moeData) {
      const optInShownTime = moeData.OPT_IN_SHOWN_TIME;
      const softAskStatus = moeData?.SOFT_ASK_STATUS || 'not_shown';

      if (softAskStatus === 'not_shown' || (softAskStatus === 'dismissed' && currentTime - optInShownTime > oneWeekInMilliseconds)) {
        moengage.call_web_push({
          soft_ask: true,
          main_class: 'moe-main-class',
          allow_class: 'moe-allow-class',
          block_class: 'moe-block-class',
        });
      }
    } else {
      // If MOE_DATA is not available, you might want to handle this case
      console.warn('MOE_DATA not found in localStorage');
    }
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      MixpanelEvent('pwa_install_clicked', {
        source: 'header',
      });
      sendPostHogEvent('pwa_install_clicked', {
        source: 'header',
      });
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        clearDeferredPrompt();
        window.sessionStorage.removeItem('deferredPromptFired');
      });
    }
  };

  let title = 'Page';
  if (location.pathname.startsWith('/chat/')) {
    title = routeTitles['/chat/:id'] || 'New Chat';
  } else {
    title = routeTitles[location.pathname] || 'Page';
  }

  const goToProfile = () => {
    MixpanelEvent('profile_opened', { source: window.location.pathname });
    sendPostHogEvent('profile_opened', { source: window.location.pathname });
    navigate('/profile');
  };

  const backClick = () => {
    navigate(-1);
  };

  const currentRoute = location.pathname;

  if (currentRoute === '/profile' || currentRoute === '/chat/new' || currentRoute === '/duelState' || currentRoute.startsWith('/chat/')) {
    return null;
  }
  const handleImageClick = () => {
    MixpanelEvent('duel_stats_clicked', { source: 'graph icon' });
    sendPostHogEvent('duel_stats_clicked', { source: 'graph icon' });
    navigate('/duelState');
  };

  return (
    <>
      <div
        className="font-poppins"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '3.9em',
          padding: '10px 0',
          backgroundColor: colors.cardBackground,
          color: colors.text,
          position: 'absolute',
          top: 0,
          zIndex: 1000,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {title === 'New Chat' ? (
            <>
              <img onClick={backClick} style={{ paddingLeft: '1em', cursor: 'pointer' }} src={ArrowLeft} alt="Arrow Left" />
              <Space w={'1em'} />
              <Text fw={500} fz={'1.2em'}>
                {title}
              </Text>
            </>
          ) : (
            <>
              <Text fw={500} ml={16} fz={'1.2em'}>
                {title}
              </Text>
              {visitedNewsFeed && currentRoute === '/news' && (
                <div
                  className="tooltip-container"
                  style={{ position: 'relative', display: 'inline-block' }}
                  onMouseEnter={(e) => {
                    const tooltip = e.currentTarget.querySelector('.tooltip');
                    tooltip.style.visibility = 'visible';
                    tooltip.style.opacity = 1;
                  }}
                  onMouseLeave={(e) => {
                    const tooltip = e.currentTarget.querySelector('.tooltip');
                    tooltip.style.visibility = 'hidden';
                    tooltip.style.opacity = 0;
                  }}
                >
                  <img
                    src={newsFeedInfo}
                    alt="News Feed"
                    style={{ marginLeft: '1em', width: '30px', marginTop: '5px', height: '30px', cursor: 'pointer' }}
                  />
                  <div
                    className="tooltip"
                    style={{
                      visibility: 'hidden',
                      width: '200px',
                      backgroundColor: colorScheme === 'dark' ? 'white' : 'black',
                      color: colorScheme === 'dark' ? '#070519' : 'white',
                      textAlign: 'center',
                      borderRadius: '8px',
                      padding: '10px',
                      position: 'absolute',
                      zIndex: 99999,
                      top: '125%',
                      left: '10%',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                      fontSize: '0.9em',
                      transition: 'opacity 0.3s',
                      opacity: 0,
                    }}
                  >
                    Your news feed is curated using AI to show you relevant stories.
                    <div
                      style={{
                        content: '""',
                        position: 'absolute',
                        bottom: '100%',
                        left: '10%',
                        borderWidth: '10px',
                        borderStyle: 'solid',
                        borderColor: `transparent transparent ${colorScheme === 'dark' ? 'white' : 'black'} transparent`,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {showInstallButton && (
            <div
              style={{
                marginTop: '7px',
                marginRight: '9px',
                cursor: 'pointer',
              }}
              onClick={handleInstallClick}
            >
              <img src={DownloadSvg} alt="Download icon" />
            </div>
          )}

          {currentRoute === '/news' && <ThemeToggle />}
          {currentRoute === '/duel' && (
            <img src={DuelStateIcon} style={{ cursor: 'pointer', marginRight: '1em' }} alt="Dual State Icon" onClick={handleImageClick} />
          )}

          {currentRoute === '/profile' ? (
            <img pr={'2em'} src={Edit2} alt="Edit" style={{ cursor: 'pointer' }} />
          ) : (
            <img
              style={{
                cursor: 'pointer',
                paddingRight: '2em',
                width: '60px',
                height: '30px',
                maxWidth: '60px',
                maxHeight: '40px',
                objectFit: 'cover',
                borderRadius: '2%',
                overflow: 'hidden',
              }}
              src={profilePic}
              alt="Profile"
              onClick={goToProfile}
            />
          )}
        </div>
      </div>
      <div
        className="moe-main-class"
        style={{
          display: 'none',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 1000,
          margin: 'auto',
        }}
      >
        <div
          className="moe-chrome-style-notification"
          style={{
            width: '90%',
            top: '30%',
            position: 'relative',
            height: 'auto',
            margin: 'auto',
            maxWidth: '400px',
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '12px',
            textAlign: 'center',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div className="moe-notification-image-wrapper" style={{ marginBottom: '20px' }}>
            <div
              style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: '#FFF9C4',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
              }}
            >
              <img src={Bell} alt="bell" />
            </div>
          </div>
          <div className="moe-text-wrapper">
            <h2 style={{ fontSize: '18px', margin: '0 0 10px', fontWeight: 'bold' }}>
              This website would like to send you awesome updates and offers!
            </h2>
            <p style={{ fontSize: '14px', color: '#666', marginBottom: '20px' }}>Notifications can be turned off anytime from browser settings.</p>
          </div>
          <button
            className="moe-btn-allow moe-allow-class"
            style={{
              width: '100%',
              padding: '12px 20px',
              backgroundColor: '#000',
              color: '#fff',
              border: 'none',
              borderRadius: '200px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            Allow
          </button>
          <button
            className="moe-btn-cancel moe-block-class"
            style={{
              width: '100%',
              padding: '12px 20px',
              backgroundColor: 'transparent',
              color: '#000',
              border: 'none',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
