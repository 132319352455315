import React, { useState, useEffect } from 'react';
import CacheClearer from './CacheClearer';
import CacheBuster from 'react-cache-buster';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import LayoutWrapper from './utils/LayoutWrapper';
import AccountDetailsPage from './components/AccountDetails';
import LoginPage from './components/Login';
import SignupPage from './components/SignUp';
import OnboardingPage from './components/Onboarding';
import DuelPage from './components/Duel';
import DuelState from './components/Duel/DuelState';
import ChatPage from './components/Chat';
import PyqPage from './components/Pyqs';
import PracticePage from './components/Practice';
import LatestNews from './components/News/latestNews';
import ProfilePage from './components/Profile';
import '@mantine/core/styles.css';
import NewsDetailPage from './components/News/newsDetailPage';
import NewsPyqPage from './components/News/newsPyqPage';
import { NewsProvider } from './context/newsProvide';
import { ThemeProvider, useTheme } from './context/themeProvider';
import AccountDetailsForm from './components/SignUp/AccountDetailsForm';
import ChatScreen from './components/Chat/TutorChat/ChatScreen';
import packageInfo from '../package.json';
import EditProfile from './components/Profile/EditProfile';
import PyqSearch from './components/Pyqs/PyqSearch';
import branch from 'branch-sdk';
import MatchGame from './components/Duel/MatchGame/MatchGame';
import Matching from './components/Duel/MatchGame/Matching';
import DuelStart from './components/Duel/MatchGame/DuelStart';
import useInstallPromptStore from './stores/eventListenerStore';
import DuelResults from './components/Duel/MatchGame/DuelResults';
import DuelReview from './components/Duel/DuelReview';
import NewsMcqPage from './components/News/newsMCQpage';
import McqDiscussAi from './components/Chat/TutorChat/McqDiscussAI';
import SelectBook from './components/Chat/AskABook/SelectBook';
import BookChat from './components/Chat/AskABook/BookChat';
import DuelFriendsList from './components/Duel/DuelFriendsList';
import CustomizePractice from './components/Practice/CustomizePractice';
import PracticeQuestions from './components/Practice/PracticeQuestions';

const AppContent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { colorScheme } = useTheme();

  const userData = localStorage.getItem('userData');

  const navigate = useNavigate();
  const [deepLinkProcessed, setDeepLinkProcessed] = useState(false);
  const isSignupNewUser = localStorage.getItem('is_signup_newUser') === 'true';

  const setDeferredPrompt = useInstallPromptStore((state) => state.setDeferredPrompt);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      window.sessionStorage.setItem('deferredPromptFired', true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [setDeferredPrompt]);

  useEffect(() => {
    if (userData) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  useEffect(() => {
    try {
      branch.data((err, data) => {
        console.log('branch data', data);

        if (err) {
          console.error('Error getting deep link data:', err);
          if (!sessionStorage.getItem('hasReloaded')) {
            sessionStorage.setItem('hasReloaded', 'true');
            window.location.reload();
          }
          return;
        }

        if (data === null || data === undefined) {
          window.location.reload();
          return;
        }
        const linkData = data.data_parsed;
        if (!deepLinkProcessed && linkData) {
          let path = '';
          let state = {};

          switch (linkData.navigate_to) {
            case 'news/id':
              path = `news/${linkData.data.id}`;
              state = {
                newsItem: linkData.data,
                newsType: linkData.newsType,
              };
              break;

            case 'news/':
              path = '/news';
              state = {};
              break;

            case '/duel-friends':
              path = '/duel-friends';
              state = {
                inviterId: linkData.inviter_id,
                inviterName: linkData.inviter_name,
                isInvite: true,
              };
              break;

            default:
              return;
          }

          if (isLoggedIn) {
            navigate(path, { state });
          } else {
            localStorage.setItem('intendedDestination', JSON.stringify({ path, state }));
            navigate('/login');
          }
          setDeepLinkProcessed(true);
        }
      });
    } catch (error) {
      console.error('Error getting deep link data:', error);
      window.location.reload();
    }
  }, [navigate, deepLinkProcessed, isLoggedIn]);

  useEffect(() => {
    if (isSignupNewUser) {
      navigate('/signupdetails');
    } else if (isLoggedIn) {
      const intendedDestination = localStorage.getItem('intendedDestination');
      if (intendedDestination) {
        const { path, state } = JSON.parse(intendedDestination);
        navigate(path, { state });
        setTimeout(() => {
          localStorage.removeItem('intendedDestination');
        }, 2000);
      }
    }
  }, [isLoggedIn, isSignupNewUser, navigate]);

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme }}>
      <React.Fragment>
        <CacheClearer />
        <LayoutWrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={isLoggedIn ? <Navigate to="/news" replace /> : <LoginPage />} />
            <Route path="/profile" element={isLoggedIn ? <ProfilePage /> : <Navigate to="/login" replace />} />
            <Route path="/edit-profile" element={isLoggedIn ? <EditProfile /> : <Navigate to="/login" replace />} />
            <Route path="/signup" element={isLoggedIn ? <Navigate to="/news" replace /> : <SignupPage />} />
            <Route path="/signupdetails" element={isLoggedIn && !isSignupNewUser ? <Navigate to="/news" replace /> : <AccountDetailsForm />} />
            <Route path="/accountDetails" element={<AccountDetailsPage />} />
            <Route path="/onboarding" element={isLoggedIn ? <Navigate to="/news" replace /> : <OnboardingPage />} />
            <Route path="/news" element={isLoggedIn ? <LatestNews /> : <Navigate to="/login" replace />} />
            <Route path="/news/:id" element={<NewsDetailPage />} />
            <Route path="/news/pyq/:id" element={<NewsPyqPage />} />
            <Route path="/news/mcq/:id" element={<NewsMcqPage />} />
            <Route path="/duel" element={<DuelPage />} />
            <Route path="/duelState" element={<DuelState />} />
            <Route path="/chat" element={isLoggedIn ? <ChatPage /> : <Navigate to="/login" replace />} />
            <Route path="/chat/mcq/" element={<McqDiscussAi />} />
            <Route path="/chat/new/" element={<ChatScreen />} />
            <Route path="/chat/:id" element={<ChatScreen />} />
            <Route path="/pyq" element={<PyqPage />} />
            <Route path="/pyqSearch" element={<PyqSearch />} />
            <Route path="/practice" element={<PracticePage />} />
            <Route path="/duel" element={<DuelPage />} />
            <Route path="/online-match-game" element={<MatchGame />} />
            <Route path="/matching" element={<Matching />} />
            <Route path="/duel-start" element={<DuelStart />} />
            <Route path="/duel-results" element={<DuelResults />} />
            <Route path="/duel-review" element={<DuelReview />} />
            <Route path="/duel-results" element={<DuelResults />} />
            <Route path="/book-chat/select-book" element={<SelectBook />} />
            <Route path="/book/:id" element={<BookChat />} />
            <Route path="/duel-friends" element={<DuelFriendsList />} />
            <Route path="/customize-practice" element={<CustomizePractice />} />
            <Route path="/practice-questions" element={<PracticeQuestions />} />
            <Route path="/review" element={<DuelReview />} />
          </Routes>
        </LayoutWrapper>
      </React.Fragment>
    </MantineProvider>
  );
};

const App = () => {
  return (
    <CacheBuster currentVersion={packageInfo.version} isEnabled={true} isVerboseMode={false}>
      <ThemeProvider>
        <NewsProvider>
          <Router>
            <AppContent />
          </Router>
        </NewsProvider>
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;
